import cx from "classnames";

import styles from "./TimeLine.module.scss";

function TimeLine({ steps, currentStep }) {
  const stepsDOM = [];

  for (let i = 1; i <= steps; i++) {
    stepsDOM.push(
      <div
        key={`step-${i}`}
        className={cx(styles.Step, { [styles.CurrentStep]: currentStep >= i })}
      >
        {i}
      </div>
    );
  }

  return (
    <div className={cx(styles.TimeLine, { [styles.Hidden]: !currentStep })}>
      {stepsDOM}
    </div>
  );
}

export default TimeLine;
