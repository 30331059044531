import { chain, startCase, toLower } from "lodash";
import Heading from "../Heading/Heading";
import styles from "./DevSelect.module.scss";
import Divider from "../Divider/Divider";
import Button from "../Button/Button";
import ScrollContainer from "../ScrollContainer/ScrollContainer";

function DevSelect({ devs, setDev, setStep }) {
  const formattedDevs = () => {
    if (!devs) {
      return null;
    } else {
      return devs
    }
  };

  const handleDevClick = (dev) => {
    setDev(dev);
  };

  return (
    <div className={styles.DevSelect}>
      <div>
        <Heading>
          Select <b>development</b>
        </Heading>
        <Divider />
      </div>
      <div className={styles.Dev}>
      <b>Redrow Standard Homes</b>
        <Button text="SELECT" color="Red" onClick={() => handleDevClick("STANDARD")}/>
      </div>
      <Divider />
      <ScrollContainer>
        {formattedDevs() &&
          formattedDevs().map((dev, i) => {
            return (
              <div
                key={i}
                onClick={() => handleDevClick(dev)}
                className={styles.DevContainer}
              >
                <div className={styles.Dev}>
                  {dev["fields"]["Name"]}
                  <Button text="SELECT" color="Red" />
                </div>
                {devs.length !== i + 1 && <Divider />}
              </div>
            );
          })}
      </ScrollContainer>
    </div>
  );
}

export default DevSelect;
