import { useState } from "react";

import styles from "./SearchForm.module.scss";

import Keyboard from "../Keyboard/Keyboard";
import TextInput from "../TextInput/TextInput";
import Heading from "../Heading/Heading";
import logo from "../../images/logo.svg";

function SearchForm({ search }) {
  const [text, setText] = useState("");

  const disabled = text.length <= 10 ? false : true;

  const addCharacter = (character) => {
    if (!disabled) {
      setText(text.concat(character));
    }
  };

  const removeCharacter = () => {
    setText(text.slice(0, -1));
  };

  return (
    <div className={styles.SearchForm}>
      <img src={logo} alt="Redrow" className={styles.Logo} />
      <Heading type="h2">
        Search your <b>postcode to compare&hellip;</b>
      </Heading>
      <TextInput text={text} placeHolder={"Enter Text"} />
      <Keyboard
        characters={[
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"],
          ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
          ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
          ["z", "x", "c", "v", "b", "n", "m"],
        ]}
        addCharacter={addCharacter}
        removeCharacter={removeCharacter}
        search={() => search(text)}
        disabled={disabled}
        canSearch={text.length ? true : false}
      />
      {/* </div> */}
    </div>
  );
}

export default SearchForm;
