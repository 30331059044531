import Heading from "../Heading/Heading";
import styles from "./RedrowHomeSelect.module.scss";
import Divider from "../Divider/Divider";
import Button from "../Button/Button";
import ScrollContainer from "../ScrollContainer/ScrollContainer";

import placeholder from "../../images/housetype.jpg";
import { useEffect, useMemo, useState } from "react";

import ecoElectric from "../../images/power.svg";
import ecoNow from "../../images/leaf.svg";

function RedrowHomeSelect({ homes, setHome, selectedDev }) {
  const [bedrooms, setBedrooms] = useState(3);

  const filteredHomes = useMemo(() => {
    const filteredHomesTemp = []
    if(selectedDev === "STANDARD") {
      homes.forEach(home => {
        if(!home['fields']['Development']) {
          filteredHomesTemp.push(home)
        }
      })
    } else if(selectedDev) {
      homes.forEach(home => {
        if(selectedDev['fields']['Plots'].includes(home['id'])) {
          filteredHomesTemp.push(home)
        }
      })
    } else {
      filteredHomesTemp.push(...homes)
    }

    return filteredHomesTemp;
  }, [selectedDev, homes])

  const numBedrooms = useMemo(() => {
    const numBedroomsTemp = []
    filteredHomes.forEach(home => {
      home["fields"]["Index"] && numBedroomsTemp.push(home["fields"]["Bedrooms"])
    })
    // Remove dups
    return [...new Set(numBedroomsTemp)].sort()
  }, [filteredHomes])

  useEffect(() => {
    setBedrooms(numBedrooms[0])
  }, [numBedrooms])

  return (
    <>
      <div className={styles.RedrowHomeSelect}>
        <div>
          <Heading>
            Select a home <b>to Compare</b>
          </Heading>
          <Divider />
        </div>
        <ScrollContainer>
          {filteredHomes.map((home, i) => {
            return home["fields"]["Index"] &&
              home["fields"]["Bedrooms"] === bedrooms ? (
              <div
                key={i}
                onClick={() => setHome(home)}
                className={styles.AddressContainer}
              >
                <div className={styles.Inner}>
                  <div className={styles.Image}>
                    <img
                      src={home["fields"]["Image"] ?? placeholder}
                      alt={home["fields"]["House Type"]}
                    />
                  </div>
                  <div className={styles.Address}>
                    <div className={styles.HouseType}>
                      The <b>{home["fields"]["House Type"]}</b>
                    </div>
                    <div className={styles.HeatingPillBedroomsContainer}>
                      <div className={styles.Bedrooms}>
                        {`${home["fields"]["Bedrooms"] ?? null}`} bedroom home
                      </div>
                      {home["fields"]["Heating Type"] && 
                        <div className={styles.HeatingPill} style={{backgroundColor: `#${home["fields"]["Heating Type"] === 'Eco Now' ? 'bbe4b1' : 'b6ebf5'}`}}>
                          <img src={home["fields"]["Heating Type"] === 'Eco Now' ? ecoNow : ecoElectric} alt="" />
                          {home["fields"]["Heating Type"]}
                        </div>
                      }
                    </div>
                  </div>
                  <Button text="SELECT" color="Red" />
                </div>
                {filteredHomes.length !== i + 1 && <Divider />}
              </div>
            ) : null;
          })}
        </ScrollContainer>
      </div>
      {filteredHomes && (
        <div className={styles.Filters}>
          {numBedrooms.map((n) => (
            <button
              key={`setBedrooms-${n}`}
              onClick={() => setBedrooms(n)}
              className={`${styles.FilterButton} ${
                n === bedrooms ? styles.FilterButtonActive : null
              }`}
            >
              {n} bed
            </button>
          ))}
        </div>
      )}
    </>
  );
}

export default RedrowHomeSelect;
