import cx from "classnames";
import { format } from "../../hooks/useCompCalc";

import Heading from "../Heading/Heading";
import Divider from "../Divider/Divider";
import styles from "./Gauge.module.scss";
import iconRuler from "../../images/icons/icon-ruler.svg";
import iconEnergy from "../../images/icons/icon-energy.svg";
import iconCO2 from "../../images/icons/icon-co2.svg";
import { useEffect, useState } from "react";

function Gauge({
  title = "Redrow",
  type = "Energy",
  band = "B",
  size = 100,
  total = 40320,
  score = 288,
  fill = 40,
}) {
  // Convert band to EPC colour
  const bandToColor = (band) => {
    switch (band) {
      case "A":
        return "#008054";

      case "B":
        return "#19b459";

      case "C":
        return "#8bc63e";

      case "D":
        return "#ffbc00";

      case "E":
        return "#ff9739";

      case "F":
        return "#ff7f1f";

      case "G":
        return "#e9153b";

      default:
        return "var(--red)";
    }
  };

  // Text labels
  const labels =
    type === "CO2"
      ? {
          icon: iconCO2,
          heading: (
            <>
              <b>CO2</b> emissions / year
            </>
          ),
          totalUnits: "tonnes",
          scoreUnits: "kg",
        }
      : {
          icon: iconEnergy,
          heading: (
            <>
              <b>Energy</b> usage / year
            </>
          ),
          totalUnits: "kWh",
          scoreUnits: "kWh",
        };

  const isSmallFill = fill <= 20;

  const [totalFill, setTotalFill] = useState(0);
  useEffect(() => {
    setTimeout(() => setTotalFill(fill), 50);
  }, [fill]);

  return (
    <div className={styles.Container}>
      <div className={styles.Intro}>
        <Heading>
          {title}
          <br />
          <b>Home</b>
        </Heading>
        <div className={styles.IntroInfo}>
          <p
            className={styles.Band}
            style={{ backgroundColor: bandToColor(band) }}
          >
            {band}
          </p>
          <p className={styles.Size}>
            <img src={iconRuler} alt="Property size:" />
            {size}m<sup>2</sup>
          </p>
        </div>
      </div>
      <Divider direction="y" />
      <div className={styles.Content}>
        <header className={styles.Header}>
          <h2 className={styles.Heading}>
            <img src={labels.icon} className={styles.Icon} alt={type} />
            <span>{labels.heading}</span>
          </h2>
          <span className={styles.Total}>
            {total} {labels.totalUnits}
          </span>
        </header>
        <div className={styles.Gauge}>
          <div
            className={styles.GaugeFill}
            style={{
              width: `${totalFill}%`,
              backgroundColor: bandToColor(band),
            }}
          >
            <p
              className={cx(
                styles.GaugeValue,
                isSmallFill ? styles.GaugeValueFloating : ""
              )}
              style={{
                color: "currentcolor",
              }}
            >
              <b>{format(Number(score), 2)}</b>
              <br />
              <span>
                {labels.scoreUnits}/m<sup>2</sup>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gauge;
