import cx from 'classnames';
import styles from './Button.module.scss';

function Button({text, onClick, color = "White"}) {
  return (
    <div className={cx(styles.Button, styles[color])} onClick={onClick}>
        {text}
    </div>
  );
}

export default Button;