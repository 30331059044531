import styles from './Spinner.module.scss';

function Spinner() {
  return (
    <div className={styles.Container}>
    <div className={styles.Spinner}>
    <div className={styles.Block}>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
        <div className={styles.Item}></div>
    </div>
  </div>
  </div>
  );
}

export default Spinner;