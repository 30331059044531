import { useEffect, useState } from "react";
import cx from "classnames";
import { useIdleTimer } from "react-idle-timer";

import { motion, AnimatePresence } from "framer-motion";

import useWindowSize from "../../hooks/useWindowSize";

import styles from "./App.module.scss";
import grayBg from "../../images/gray-bg.svg";
import backgroundVideo from "../../videos/newBackground.mp4";

import SearchForm from "../SearchForm/SearchForm";
import AddressSelect from "../AddressSelect/AddressSelect";
import Error from "../Error/Error";
import Spinner from "../Spinner/Spinner";
import RedrowHomeSelect from "../RedrowHomeSelect/RedrowHomeSelect";
import useApiData from "../../hooks/useApiData";
import useAirtableData from "../../hooks/useAirtableData";
import Container from "../Container/Container";
import TimeLine from "../TimeLine/TimeLine";
import HomeComparison from "../HomeComparison/HomeComparison";
import NavButton from "../NavButton/NavButton";
import Slider from "../Slider/Slider";
import NavBar from "../NavBar/NavBar";
import InfoModel from "../InfoModal/InfoModal";
import IdleModal from "../IdleModal/IdleModal";
import MobileModal from "../MobileModal/MobileModal";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import EfficientHome from "../EfficientHome/EfficientHome";
import useQueryString from "../../hooks/useQueryString";
import DevSelect from "../DevSelect/DevSelect";

function App() {
  const [apiData, apiError, isSearching, search, apiDataReset] = useApiData();
  const [dev, setDev] = useState(null);
  const [home, setHome] = useState(null);
  const [redrowHome, setRedrowHome] = useState(null);

  const [step, setStep] = useState(0);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const [isIdle, setIsIdle] = useState(false);

  const [airtableData, airtableError, getAirtableData, resetAirtableData, isSearchingAirtable] =
    useAirtableData();

  const windowSize = useWindowSize();

  const params = useQueryString();

  const onIdle = () => {
    backToStep();
    setIsIdle(true);
  };

  const onActive = (event) => {
    setIsIdle(false);
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: 300000,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
  });

  const backToStep = (newStep) => {
    switch (newStep) {
      case 1:
        setHome(null);
        setStep(1);
        break;
      case 2:
        setDev(null);
        getAirtableData('devs')
        setStep(2);
        break;
      case 3:
        setRedrowHome(null);
        getAirtableData('plots')
        setStep(3);
        break;
      case 4:
        setStep(4);
        break;
      case 5:
        setStep(3);
        break;
      default:
        setHome(null);
        setDev(null);
        setRedrowHome(null);
        apiDataReset();
        resetAirtableData();
        setStep(0);
    }
  };

  useEffect(() => {
    apiData && setStep(1);
  }, [apiData]);

  useEffect(() => {
    home && setStep(2);
    home && getAirtableData('devs');
  }, [home]);

  useEffect(() => {
    dev && setStep(3);
    dev && getAirtableData('plots');
  }, [dev]);

  useEffect(() => {
    redrowHome && setStep(4);
  }, [redrowHome]);

  let content;

  switch (step) {
    case 0:
      content = (
        <Container>{!apiData && <SearchForm search={search} />}</Container>
      );
      break;
    case 1:
      content = (
        <Container background>
          <AddressSelect
            homes={apiData["rows"]}
            setHome={setHome}
            setStep={setStep}
          />
        </Container>
      );
      break;
    case 2:
      content = (
        <Container background>
          <DevSelect 
            devs={airtableData}
            setDev={setDev}
            setStep={setStep}
          />
        </Container>
      );
      break;
    case 3:
      content = (
        <Container background>
          <RedrowHomeSelect homes={airtableData} setHome={setRedrowHome} selectedDev={dev}/>
        </Container>
      );
      break;
    case 4:
      content = (
        <>
          <Container>
            <HomeComparison
              userHome={home}
              redrowHome={redrowHome}
              nextButton={<NavButton action={() => setStep(5)} />}
            />
          </Container>
        </>
      );
      break;
    case 5:
      content = (
        <Slider
          userHome={home}
          redrowHome={redrowHome}
          prevButton={
            <NavButton action={() => backToStep(4)} direction="prev" />
          }
        />
      );
      break;
    case 6:
      content = <EfficientHome />;
      break;
    default:
      content = <Error error={"Step not defined"} />;
      break;
  }

  return (
    <div
      className={cx(styles.App, { [styles.HomeScreen]: !step })}
      style={{
        background: `transparent`,
      }}
    >
      <TimeLine steps={5} currentStep={step} />

      {isSearching || apiError || airtableError || isSearchingAirtable ? (
        <>
          {isSearching && <Spinner />}
          {isSearchingAirtable && <Spinner />}
          {apiError && <Error error={apiError} resetApp={backToStep} />}
          {airtableError && (
            <Error error={airtableError} resetApp={backToStep} />
          )}
        </>
      ) : (
        <div className={styles.Main}>
          <AnimatePresence initial={false}>
            <motion.div
              style={{ width: "100%", maxHeight: "75vh" }}
              key={step}
              transition={{ duration: 0.5 }}
              initial={{ x: "100vw", opacity: 0, position: "absolute" }}
              animate={{ x: "0", opacity: 1, position: "static" }}
              exit={{ x: "-100vw", opacity: 0, position: "absolute" }}
            >
              {content}
            </motion.div>
          </AnimatePresence>
        </div>
      )}
      <NavBar
        openInfo={setIsInfoOpen}
        back={() => backToStep(step === 6 ? 1 : step - 1)}
        home={() => backToStep(0)}
        queryParams={params}
      />
      <InfoModel isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
      <IdleModal isOpen={isIdle} />
      <MobileModal windowSize={windowSize} />

      <div className={styles.Video}>
        <VideoPlayer videoURL={backgroundVideo} type="video/mp4" loop />
      </div>
    </div>
  );
}

export default App;
