import styles from "./Error.module.scss";
import backIcon from "../../images/icons/icon-back.svg";
import Heading from "../Heading/Heading";

function Error({ error, resetApp }) {
  return (
    <>
      {String(error).includes("No Results found for ") ? (
        <div className={styles.Error}>
          <Heading type="h2">
            {error}
            <br />
            Please try again.
          </Heading>
          <img
            src={backIcon}
            className={styles.Icon}
            alt={"Back"}
            onClick={() => resetApp()}
          />
        </div>
      ) : (
        <div className={styles.Error}>
          <Heading type="h2">
            We are having difficulty fetching the requested EPC data.
            <br />
            Please try again later.
          </Heading>
          <img
            src={backIcon}
            className={styles.Icon}
            alt={"Back"}
            onClick={() => resetApp()}
          />
        </div>
      )}
    </>
  )
}

export default Error;
