import cx from 'classnames';

import styles from './Container.module.scss';

function Container({children, background}) {
  return (
    <div className={cx(styles.Container, {[styles.Background] : background})}>
      {children}
    </div>
  );
}

export default Container;