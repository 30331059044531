import cx from "classnames";
import styles from "./Divider.module.scss";

function Divider({ children, direction = "x" }) {
  return (
    <div
      className={cx(styles.Divider, { [styles.Vertical]: direction === "y" })}
    >
      {children}
    </div>
  );
}

export default Divider;
