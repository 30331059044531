import { chain, startCase, toLower } from "lodash";
import Heading from "../Heading/Heading";
import styles from "./AddressSelect.module.scss";
import Divider from "../Divider/Divider";
import Button from "../Button/Button";
import ScrollContainer from "../ScrollContainer/ScrollContainer";

function AddressSelect({ homes, setHome, setStep }) {
  const formattedHomes = () => {
    if (!homes) return null;

    homes = homes.map((home) => {
      const number = !isNaN(home.address1.split(" ")[0])
        ? Number(home.address1.split(" ")[0])
        : home.address1;

      return {
        ...home,
        "address-number": number,
        address: startCase(toLower(home.address.replace(",", ""))),
        address1: startCase(toLower(home.address1.replace(",", ""))),
      };
    });

    return chain(homes).sortBy("address-number").uniqBy("address1").value();
  };

  const handleHomeClick = (home) => {
    if (home["current-energy-rating"] === "A") {
      setStep(6);
    } else {
      setHome(home);
    }
  };

  return (
    <div className={styles.AddressSelect}>
      <div>
        <Heading>
          Select your home from
          <br />
          the <b>properties below</b>
        </Heading>
        <Divider />
      </div>
      <ScrollContainer>
        {formattedHomes() &&
          formattedHomes().map((home, i) => {
            return (
              <div
                key={i}
                onClick={() => handleHomeClick(home)}
                className={styles.AddressContainer}
              >
                <div className={styles.Address}>
                  {home["address"]}
                  <Button text="SELECT" color="Red" />
                </div>
                {homes.length !== i + 1 && <Divider />}
              </div>
            );
          })}
      </ScrollContainer>
    </div>
  );
}

export default AddressSelect;
