import { useEffect, useState } from "react";

import axios from "axios";

export default function useAirtableData() {
  const [airtableData, setAirtableData] = useState([]);
  const [airtableError, setAirtableError] = useState(null);
  const [airtableOffset, setAirtableOffset] = useState(null);
  const [isSearchingAirtable, setIsSearchingAirtable] = useState(false)

  useEffect(() => {
    if (airtableError) {
      console.error(airtableError);
    }
  }, [airtableError]);

  useEffect(() => {
    if (airtableOffset) {
      getAirtableData();
    }
  }, [airtableOffset]);

  const getAirtableData = (data = 'plots') => {
    setAirtableError(null);
    setIsSearchingAirtable(true)

    const url = data === 'plots' ? "https://api.airtable.com/v0/appNb7RUlRXBkkzcY/Redrow%20Homes" : "https://api.airtable.com/v0/appNb7RUlRXBkkzcY/developments"
    const filter = "";
    const offset = airtableOffset ? `&offset=${airtableOffset}` : "";

    axios
      .get(`${url}${filter}${offset}`, {
        headers: {
          Authorization: "Bearer patYwEMR5vkoqYpZf.9cfd5354d0e2734684373f78302be5940d5dcd6fcfdbdf8ba0993463538b6575",
        },
      })
      .then((response) => {
        response.data["records"]
          ? setAirtableData(
              airtableOffset
                ? [...airtableData, ...response.data["records"]]
                : [...response.data["records"]]
            )
          : setAirtableError(`No Results from airtable`);
        response.data["offset"]
          ? setAirtableOffset(response.data.offset)
          : setAirtableOffset(null);
      })
      .catch((error) => {
        setAirtableError(error);
      })
      .finally(() => {
        setIsSearchingAirtable(false)
      });
  };

  const resetAirtableData = () => {
    setAirtableData([]);
    setAirtableError(null);
    setAirtableOffset(null);
  };

  return [airtableData, airtableError, getAirtableData, resetAirtableData, isSearchingAirtable];
}
