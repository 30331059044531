import styles from "./HomeComparison.module.scss";
import { useState } from "react";
import NavButton from "../NavButton/NavButton";
import { AnimatePresence, motion } from "framer-motion";
import { format } from "../../hooks/useCompCalc";
import Gauge from "../Gauge/Gauge";

function HomeComparison({ userHome, redrowHome, nextButton }) {
  const [showSlide, setShowSlide] = useState("CO2");

  // kWh
  const redrowKWHPerYear = parseFloat(
    redrowHome["fields"]["Primary energy kWh/year"]
  ).toFixed(0);
  const redrowKWHm2 = redrowHome["fields"]["Primary energy kWh/m2/year"];
  const userKWHPerYear = (
    parseFloat(userHome["energy-consumption-current"]) *
    parseFloat(userHome["total-floor-area"])
  ).toFixed(0);
  const userKWHm2 = userHome["energy-consumption-current"];
  const KWHmax = Math.max(redrowKWHm2, userKWHm2);

  // CO2
  const userCo2m2 = userHome["co2-emiss-curr-per-floor-area"];
  const userCo2Total = (
    parseFloat(userHome["co2-emiss-curr-per-floor-area"]) *
    parseFloat(userHome["total-floor-area"])
  ).toFixed(2);
  const redrowCo2m2 = redrowHome["fields"]["CO2 Total kg/m2/year"];
  const redrowCo2Total = redrowHome["fields"]["CO2 Total kg/year"];
  const co2m2Max = Math.max(userCo2m2, redrowCo2m2);

  // Floor size m2
  const userM2 = userHome["total-floor-area"];
  const redrowM2 = redrowHome["fields"]["m2"];

  // EPC band
  const userBand = userHome["current-energy-rating"];
  const redrowBand = redrowHome["fields"]["SAP Current"].split(" ")[0];

  return showSlide === "CO2" ? (
    <div className={styles.HomeComparison}>
      <AnimatePresence initial={false}>
        <motion.div
          style={{ width: "100%", maxHeight: "75vh" }}
          key={0}
          transition={{ duration: 0.5 }}
          initial={{
            x: `${showSlide === "CO2" ? "-100vw" : "100vw"}`,
            opacity: 0,
            position: "absolute",
          }}
          animate={{ x: "0", opacity: 1, position: "static" }}
          exit={{
            x: `${showSlide === "CO2" ? "-100vw" : "100vw"}`,
            opacity: 0,
            position: "absolute",
          }}
        >
          <Gauge
            title={"Current"}
            type="CO2"
            band={userBand}
            size={format(userM2, 0)}
            total={format(userCo2Total / 1000, 2, 1)}
            score={format(userCo2m2)}
            fill={(userCo2m2 / co2m2Max) * 100}
          />
          <Gauge
            title={"Redrow"}
            type="CO2"
            band={redrowBand}
            size={format(redrowM2, 0)}
            total={format(redrowCo2Total / 1000, 2, 1)}
            score={format(redrowCo2m2)}
            fill={(redrowCo2m2 / co2m2Max) * 100}
          />
          <div className={styles.NextButton}>
            <NavButton action={() => setShowSlide("energy")} />
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  ) : (
    <div className={styles.HomeComparison}>
      <AnimatePresence initial={false}>
        <motion.div
          style={{ width: "100%", maxHeight: "75vh" }}
          key={1}
          transition={{ duration: 0.5 }}
          initial={{
            x: `${showSlide === "energy" ? "100vw" : "100vw"}`,
            opacity: 0,
            position: "absolute",
          }}
          animate={{ x: "0", opacity: 1, position: "static" }}
          exit={{
            x: `${showSlide === "energy" ? "100vw" : "-100vw"}`,
            opacity: 0,
            position: "absolute",
          }}
        >
          <Gauge
            title={"Current"}
            type="Energy"
            band={userBand}
            size={format(userM2, 0)}
            total={format(userKWHPerYear, 2, 0)}
            score={format(userKWHm2)}
            fill={(userKWHm2 / KWHmax) * 100}
          />
          <Gauge
            title={"Redrow"}
            type="Energy"
            band={redrowBand}
            size={format(redrowM2, 0)}
            total={format(redrowKWHPerYear, 2, 0)}
            score={format(redrowKWHm2)}
            fill={(redrowKWHm2 / KWHmax) * 100}
          />
          <div className={styles.PrevButton}>
            <NavButton action={() => setShowSlide("CO2")} direction="prev" />
          </div>
          <div className={styles.NextButton}>{nextButton}</div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default HomeComparison;
