import cx from "classnames";
import styles from "./Key.module.scss";

function Key({
  character,
  onTouch,
  special = false,
  light = false,
  disabled = false,
}) {
  return (
    <div
      className={cx(styles.Key, {
        [styles.Special]: special,
        [styles.Light]: light,
        [styles.Disabled]: disabled,
      })}
      onClick={!disabled ? onTouch : null}
    >
      {character}
    </div>
  );
}

export default Key;
