import { useState } from "react";
import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import styles from "./Slider.module.scss";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import NavButton from "../NavButton/NavButton";
import useCompCalc from "../../hooks/useCompCalc";

// WebM Videos
import tree from "../../videos/Tree.mp4";
import kettle from "../../videos/Kettle.mp4";
import coins from "../../videos/Coins.mp4";
import car from "../../videos/Car.mp4";
import bulb from "../../videos/Bulb.mp4";
import bricks from "../../videos/Bricks.mp4";
import balloon from "../../videos/Balloon.mp4";
import confetti from "../../videos/Confetti.mp4";
import house from "../../videos/House.mp4";

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? "100vh" : "-100vw",
      opacity: 0,
      position: "absolute",
      transform: "scale(0.5)",
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    position: "static",
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? "100vh" : "-100vw",
      opacity: 0,
      position: "absolute",
      transform: "scale(0.5)",
    };
  },
};

function Slider({ userHome, redrowHome, prevButton }) {
  const redrowKgPerYear = parseFloat(
    redrowHome["fields"]["CO2 Total kg/year"]
  ).toFixed(1);
  const userKgPerYear = (
    parseFloat(userHome["co2-emissions-current"]) * 1000
  ).toFixed(1);

  const redrowEnergyPerYear = parseFloat(
    redrowHome["fields"]["Primary energy kWh/year"]
  ); //kWh/year
  const userEnergyPerYear =
    parseFloat(userHome["energy-consumption-current"]) *
    parseFloat(userHome["total-floor-area"]); //kWh/year

  const energyDiff = userEnergyPerYear - redrowEnergyPerYear;
  const co2Diff = userKgPerYear - redrowKgPerYear;
  const savings = useCompCalc(co2Diff, energyDiff);

  const userCo2m2 = userHome["co2-emiss-curr-per-floor-area"];
  const redrowCo2m2 = redrowHome["fields"]["CO2 Total kg/m2/year"];
  const energyCo2m2Diff = userCo2m2 - redrowCo2m2;

  const redrowKWHm2 = redrowHome["fields"]["Primary energy kWh/m2/year"];
  const userKWHm2 = userHome["energy-consumption-current"];
  const kWhM2Diff = userKWHm2 - redrowKWHm2;

  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  const navButtons = (
    <div className={styles.SlideNav}>
      <div className={styles.NavLeft}>
        {page === 0 ? (
          prevButton
        ) : (
          <NavButton action={() => paginate(-1)} direction="prev" />
        )}
      </div>
      <div className={styles.NavRight}>
        <NavButton action={() => paginate(1)} />
      </div>
    </div>
  );

  const slides = [
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.trees}</Heading>
        <VideoPlayer videoURL={tree} />
      </div>
    </Container>,
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.kettle}</Heading>
        <VideoPlayer videoURL={kettle} />
      </div>
    </Container>,
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.coins}</Heading>
        <VideoPlayer videoURL={coins} />
      </div>
    </Container>,
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.car}</Heading>
        <VideoPlayer videoURL={car} />
      </div>
    </Container>,
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.lightbulbs}</Heading>
        <VideoPlayer videoURL={bulb} />
      </div>
    </Container>,
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.bricks}</Heading>
        <VideoPlayer videoURL={bricks} />
      </div>
    </Container>,
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">{savings.balloons}</Heading>
        <VideoPlayer videoURL={balloon} loop />
      </div>
    </Container>,
  ];

  const motionTrans = {
    x: { type: "spring", stiffness: 300, damping: 30 },
    opacity: { duration: 0.2 },
  };

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={motionTrans}
        >
          {energyDiff > 0 ? (
            <div className={styles.SlidesContainer}>
              <div className={styles.OuterSlideLeft}>
                {slides[wrap(0, slides.length, page - 1)]}
              </div>
              <div className={styles.InnerSlide}>
                {slides[wrap(0, slides.length, page)]}
                {navButtons}
              </div>
              <div className={styles.OuterSlideRight}>
                {slides[wrap(0, slides.length, page + 1)]}
              </div>
            </div>
          ) : energyCo2m2Diff < 0 ? (
            <Container background>
              <div className={styles.Slide}>
                <Heading type="h3">
                  <b>Congratulations</b>
                  <br /> your home is more efficient
                </Heading>
                <VideoPlayer videoURL={confetti} loop />
              </div>
            </Container>
          ) : (
            <Container background>
              <div className={styles.Slide}>
                <Heading type="h3">
                  Every year this Redrow home will use
                  <br />
                  <b>{kWhM2Diff.toFixed(0)}kWh</b> less energy per m<sup>2</sup>{" "}
                  <br />
                  and emit <b>{energyCo2m2Diff.toFixed(2)}kg</b> less co2 per m
                  <sup>2</sup>.
                </Heading>
                <VideoPlayer videoURL={house} loop />
              </div>
            </Container>
          )}
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export default Slider;
