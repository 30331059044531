import Container from "../Container/Container";
import Heading from "../Heading/Heading";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import styles from "../Slider/Slider.module.scss";
import confetti from "../../videos/Confetti.webm";

function EfficientHome() {
  return (
    <Container background>
      <div className={styles.Slide}>
        <Heading type="h3">
          <b>Congratulations</b>
          <br /> you already have an A rated home
        </Heading>
        <VideoPlayer videoURL={confetti} loop />
      </div>
    </Container>
  );
}

export default EfficientHome;
