import cx from "classnames";

import styles from "./ScrollContainer.module.scss";
import "./ScrollBarStylesOverride.scss";

import SimpleBar from "simplebar-react";

function ScrollContainer({ children }) {
  const scrollbarHandleSize = 64; // scrollbar height

  return (
    <div className={cx(styles.ScrollContainer)}>
      <SimpleBar
        style={{ maxHeight: "100%" }}
        scrollbarMinSize={scrollbarHandleSize}
        scrollbarMaxSize={scrollbarHandleSize}
        autoHide={false}
      >
        <div className={styles.Children}>{children}</div>
      </SimpleBar>
    </div>
  );
}

export default ScrollContainer;
