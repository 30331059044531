export const format = (value, precision = 1, decimals = 0) => {
  const round = (value, precision, decimals) => {
    var multiplier = Math.pow(10, precision || 0);
    return (Math.round(value * multiplier) / multiplier).toFixed(decimals);
  };

  return round(value, precision, decimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function useCompCalc(co2Diff, energyDiff) {
  const kwhCupOfTea = 0.0375;
  const kwhLightBulbYear = 876;
  const kwhPerMile = 0.3571428;

  const kgPerCubicMeter = 1.836;
  const balloonVol = 0.0070792;

  const averageBrickWeight = 3;

  const co2CubicMeters = co2Diff * kgPerCubicMeter;
  const balloons = co2CubicMeters / balloonVol;

  const treeKgYear = 21;

  const trees = co2Diff / treeKgYear;

  const bricks = co2Diff / averageBrickWeight;

  const cupsOfTea = energyDiff / kwhCupOfTea;
  const yearsLightBulb = energyDiff / kwhLightBulbYear;
  const miles = energyDiff / kwhPerMile;

  const elecBalance = 0.195;
  const gasBalance = 0.805;
  const elecCostKwh = 0.2862;
  const gasCostKwh = 0.0742;

  //https://www.britishgas.co.uk/energy/guides/average-bill.html

  const costElec = energyDiff * elecBalance * elecCostKwh;
  const costGas = energyDiff * gasBalance * gasCostKwh;
  const costTotal = costElec + costGas;

  return {
    kettle: (
      <span>
        Boil enough water for <b>{format(cupsOfTea, 0)}</b> cups&nbsp;of&nbsp;tea
      </span>
    ),
    lightbulbs: (
      <span>
        Save enough energy to light a 100w lightbulb for{" "}
        <b>{format(yearsLightBulb, 0)}</b>&nbsp;years
      </span>
    ),
    car: (
      <span>
        Drive an electric car <b>{format(miles, 0)}</b>&nbsp;miles
      </span>
    ),
    trees: (
      <span>
        Save the amount of carbon that <b>{format(trees, 0)}</b> trees absorb in&nbsp;a&nbsp;year.
      </span>
    ),
    balloons: (
      <span>
        Fill <b>{format(balloons, 0)}</b> balloons with&nbsp;CO2
      </span>
    ),
    bricks: (
      <span>
        That's the same weight as <b>{format(bricks, 0)}</b>&nbsp;bricks
      </span>
    ),
    coins: (
      <span>
        You could save up to <b>£{format(costTotal, 2)}</b>&nbsp;per&nbsp;year
      </span>
    ),
  };
}
