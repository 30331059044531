import cx from 'classnames';
import styles from './BackButton.module.scss';

function BackButton({development, color="Red"}) {
  return (
    <a 
      className={cx(styles.BackButton, styles[color])} 
      href={`https://${development}.redrowapp.co.uk`}
    >
        Back to Development
    </a>
  );
}

export default BackButton;