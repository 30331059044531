import cx from 'classnames';
import styles from './MobileModal.module.scss';
import Heading from '../Heading/Heading';

function MobileModal({windowSize}) {

  const minHeight = 600;
  const minWidth = 700;

  const shouldShow = (windowSize.height < minHeight || windowSize.width < minWidth || windowSize.height > windowSize.width) ? true : false

  return (
    <div className={cx(styles.MobileModal, {[styles.Open] : shouldShow})}>
        <Heading type="h3" white><strong>Resolution not supported.</strong><br/>Please resize your browser window. </Heading>
    </div>
  );
}

export default MobileModal;