import styles from "./NavButton.module.scss";
import rightArrowIcon from "../../images/icons/icon-right.svg";
import leftArrowIcon from "../../images/icons/icon-left.svg";

function NavButton({ direction = "next", action }) {
  const icon = direction === "next" ? rightArrowIcon : leftArrowIcon;
  return (
    <div className={styles.NavButton} onClick={() => action()}>
      <img className={styles.Icon} src={icon} alt={direction} />
    </div>
  );
}

export default NavButton;
