import styles from './TextInput.module.scss';
import searchIcon from '../../images/icons/icon-search.svg'

//Doesn't actually need to handle input, just display the text prop
function TextInput({text, placeHolder}) {
  return (
    <div className={styles.TextInput}>
        {text !== '' ? <span className={styles.Text}>{text}</span> : <span className={styles.Placeholder}>Enter Your Postcode</span>}
        <img className={styles.Icon} src={searchIcon}/>
    </div>
  );
}

export default TextInput;
