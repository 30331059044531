import cx from "classnames";
import styles from "./Heading.module.scss";

function Heading({ children, type = "h1", white = false, margin = true, content }) {
  return (
    <h1
      className={cx(styles.Heading, styles[type], {
        [styles.NoMargin]: !margin,
        [styles.White]: white,
      })}
    >
      {children ? children : content}
    </h1>
  );
}

export default Heading;
