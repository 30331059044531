import styles from "./VideoPlayer.module.scss";

function VideoPlayer({ videoURL, type = "video/mp4", loop = false }) {
  return (
    <div className={styles.VideoPlayer}>
      <video className={styles.Video} autoPlay muted loop={loop}>
        <source src={videoURL} type={type} />
      </video>
    </div>
  );
}

export default VideoPlayer;
