import cx from "classnames";
import styles from "./IdleModal.module.scss";
import icon from "../../images/icons/icon-touch.svg";
import Heading from "../Heading/Heading";

function IdleModal({ isOpen }) {
  return (
    <div className={cx(styles.IdleModal, { [styles.Open]: isOpen })}>
      <img className={styles.Icon} src={icon} alt="Touch icon" />
      <Heading type="h3">
        <strong>Touch To Start</strong>
      </Heading>
    </div>
  );
}

export default IdleModal;
