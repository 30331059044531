import styles from "./NavBar.module.scss";
import infoIcon from "../../images/icons/icon-info.svg";
import backIcon from "../../images/icons/icon-back.svg";
import homeIcon from "../../images/icons/icon-home.svg";
import BackButton from "../BackButton/BackButton";

function NavBar({ openInfo, back, home, queryParams }) {
  return (
    <div className={styles.NavBar}>
      <div className={styles.IconGroup}>
        {queryParams.get('backto') && <BackButton development={queryParams.get('backto')}/>}
      </div>
      <div className={styles.Disclaimer}>
        Information and pricing given is indicative only
      </div>
      <div className={styles.IconGroup}>
        <img className={styles.Icon} src={backIcon} onClick={back} style={{marginTop: '5px'}}/>
        <img className={styles.Icon} src={homeIcon} onClick={home} />
        <img className={styles.Icon} src={infoIcon} onClick={openInfo} style={{marginTop: '5px'}}/>
      </div>
    </div>
  );
}

export default NavBar;
